import T1 from "./images/Nishara.png";
import T2 from "./images/Jayani.png";
import T3 from "./images/suresh.jpg";
import T4 from "./images/Madushika.jpg";
import Avatar from "./images/avatar.jpg";

const teamdata = [
  {
    id: 1,
    img: T1,
    member: "Nishara Jayasinghe",
    position: "General Manager (Surveyor)",
  },
  {
    id: 2,
    img: T2,
    member: "Jayani Nadunika",
    position: "Sales & Support (Surveyor)",
  },
  {
    id: 3,
    img: T3,
    member: "Suresh Gunarathna",
    position: "Surveyor",
  },
  {
    id: 4,
    img: T4,
    member: "Madhushika Nuwangi",
    position: "Sales & Support (Surveyor)",
  },
];

export default teamdata;
